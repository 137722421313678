window.htmx = require("htmx.org");

import {Modal, Offcanvas, Popover, Toast, Tooltip} from "bootstrap";
import Choices from "choices.js";
import Alpine from "alpinejs";

import {formatNumber, parseNumber} from "./utilities";

window.Modal = Modal;
window.Alpine = Alpine;
document.addEventListener("alpine:init", () => {
    let shownDocument = null;
    const LEGAL_WORKING_TYPE = "legal",
        PART_TIME_WORKING_TYPE = "part_time",
        EQUIVALENCE_TIME_WORKING_TYPE = "eq",
        LEGAL_WORKING_TIME_VALUE = 40,
        MIN_WORKING_TIME = 1,
        YES = "yes",
        NO = 'no',
        PARTNER = "partner",
        MANAGER = "manager",
        MAX_EQUIVALENCE_TIME_VALUE = 168,
        MAX_PART_TIME_VALUE = 7,
        VERSION_1 = "v1",
        PASSWORD = "password",
        TEXT = "text";

    Alpine.data("actionMessage", () => ({
        shown: false,
        timeout: null,

        display() {
            if (!this.$el.dataset.targetId) {
                console.error("Missing required attribute: data-target-id");
            }

            if (this.$el.dataset.targetId === this.$event.target.id) {
                clearTimeout(this.timeout);
                this.shown = true;
                this.timeout = setTimeout(() => {
                    this.shown = false;
                }, 200);
            }
        },

        content: {
            ["x-show.transition.out.opacity.duration.1500ms"]: "shown",
            ["x-transition:leave.opacity.duration.1500ms"]: "",
            ["x-on:htmx:after-swap.window"]() {
                this.display();
            },

            [":style"]() {
                return {display: "none"};
            },
        },
    }));

    Alpine.data("toggleablePasswordInput", () => ({
        visible: false,

        passwordToggle: {
            ["x-on:click"]() {
                this.$refs.passwordInput.type = this.visible ? PASSWORD : TEXT
                this.visible = !this.visible;
            },
        },
    }));

    Alpine.data("countryChoices", () => ({
        init() {
            new Choices(this.$root, {itemSelectText: ""});
        },
    }));

    Alpine.data("confirmationModal", (confirmationModalSelector) => ({
        confirmationTrigger: {
            ["x-on:click"]() {
                const confirmationModal = Modal.getOrCreateInstance(
                    confirmationModalSelector,
                );
                confirmationModal.show();

                const handleConfirmation = () => {
                    htmx.trigger(this.$el, "confirmed");
                };
                const confirmationModalEl = document.querySelector(
                    confirmationModalSelector,
                );
                const confirmationButton = confirmationModalEl.querySelector(
                    "button[data-confirmation-button]", // All confirmation modal button must have this attribute
                );
                confirmationButton.addEventListener(
                    "click",
                    function confirmationButtonEventListener() {
                        handleConfirmation();
                        confirmationButton.removeEventListener(
                            "click",
                            confirmationButtonEventListener,
                        );
                        confirmationModal.hide();
                    },
                );
            },
        },
    }));

    Alpine.data("toggeableInput", (initialSelectedOption = false) => ({
        selectedOption: initialSelectedOption,
        toggleTrigger: {
            ["x-model"]: "selectedOption",
        },
        toggledInput(workingTimeInitial = LEGAL_WORKING_TIME_VALUE) {
            return {
                [":disabled"]() {
                    return this.selectedOption === LEGAL_WORKING_TYPE;
                },
                [':value']() {
                    return this.selectedOption === LEGAL_WORKING_TYPE ? LEGAL_WORKING_TIME_VALUE : workingTimeInitial | MIN_WORKING_TIME
                },
                ['x-bind']() {
                    return "workingTimeInitial"
                },
            };
        },
    }));

    Alpine.data("tabBar", () => ({
        tabSwitch: {
            ["x-on:htmx:after-on-load"]() {
                const currentTab = this.$root.querySelector("[aria-selected=true]");
                currentTab.setAttribute("aria-selected", "false");
                currentTab.classList.remove("active");
                const newActiveTab = this.$event.target;
                newActiveTab.setAttribute("aria-selected", "true");
                newActiveTab.classList.add("active");
            },
        },
    }));

    Alpine.data("toggleablePasswordInput", () => ({
        visible: false,

        passwordToggle: {
            ["x-on:click"]() {
                this.$refs.passwordInput.type = this.visible ? PASSWORD : TEXT;
                this.visible = !this.visible;
            },
        },
    }));

    Alpine.data("sumComponent", () => ({
        addendOne: 0,
        addendTwo: 0,

        init() {
            this.addendOne = formatNumber(this.$el.dataset.addendOne);
            this.addendTwo = formatNumber(this.$el.dataset.addendTwo);
        },
        first: {
            ["@input"](event) {
                this.addendOne = formatNumber(event.target.value);
            },
            ["@change"](event) {
                this.addendOne = formatNumber(event.target.value);
            },
        },
        second: {
            ["@input"](event) {
                this.addendTwo = formatNumber(event.target.value);
            },
            ["@change"](event) {
                this.addendTwo = formatNumber(event.target.value);
            },
        },
        sum: {
            [":value"]() {
                const total = parseNumber(this.addendOne) + parseNumber(this.addendTwo);
                return formatNumber(`${total}`);
            },
        },
    }));

    Alpine.data("DraftComponent", () => ({
        isDraft: false,

        init() {
            this.isDraft = this.$el.dataset.isDraft;
        }
    }))

    Alpine.data("monetaryComponent", () => ({
        amount: 0,
        init() {
            this.amount = formatNumber(this.$el.dataset.amount);
        },
        formatInput: {
            ["@input"](event) {
                let rawValue = event.target.value.replace(/\D/g, '');
                this.amount = formatNumber(rawValue);
                event.target.value = this.amount;
            }
        },
    }));

    Alpine.data("inalienabilityComponent", () => ({
        state: YES,
        duration: 0,
        init() {
            this.state = this.$el.dataset.state;
            this.duration = 1 * this.$el.dataset.duration;
        },
        change: {
            [":value"]() {
                return this.state === NO ? 0 : 1 * this.duration;
            },
            [":readonly"]() {
                return this.state === NO;
            },
        },
    }));

    Alpine.data("roleComponent", () => ({
        role: "manager",
        actions: 0,
        total: 0,
        mandate: 0,
        version: "v1",
        init() {
            this.role = this.$el.dataset.role;
            this.actions = 1 * this.$el.dataset.actions;
            this.total = 1 * this.$el.dataset.total;
            this.mandate = 1 * this.$el.dataset.mandate;
            this.version = this.$el.dataset.version;
        },
        mandateChange: {
            [":readonly"]() {
                return this.role === PARTNER;
            },
            [":value"]() {
                return this.role === PARTNER ? 0 : 1 * this.mandate;
            },
        },
        actionsChange: {
            [":readonly"]() {
                return this.role === MANAGER || this.version === VERSION_1;
            },
            [":value"]() {
                return this.role === MANAGER ? 0 : 1 * this.total;
            },
        },
    }));

    Alpine.data("workingTimeComponent", (disabledValue) => ({
        triggerValue: disabledValue,
        workingTimeHoursVisible: false,
        targetValue: LEGAL_WORKING_TIME_VALUE,
        init() {
            this.triggerValue = this.$el.dataset.triggerValue || disabledValue;
            this.targetValue = 1 * this.$el.dataset.targetValue || LEGAL_WORKING_TIME_VALUE;
            this.workingTimeHoursVisible = this.triggerValue === PART_TIME_WORKING_TYPE
        },
        workingTimeHours: {
            ['x-show']() {
                return this.workingTimeHoursVisible
            },
        },
        workingTimeType: {
            ['@change'](event) {
                this.triggerValue = event.target.value
                this.workingTimeHoursVisible = this.triggerValue === PART_TIME_WORKING_TYPE
            },
        },
        typeChanged: {
            [":readonly"]() {
                return this.triggerValue === disabledValue;
            },
            [":value"]() {
                return this.triggerValue === disabledValue ? LEGAL_WORKING_TIME_VALUE : MIN_WORKING_TIME;
            },
            [":max"]() {
                if (this.triggerValue === EQUIVALENCE_TIME_WORKING_TYPE) {
                    return MAX_EQUIVALENCE_TIME_VALUE
                } else if (this.triggerValue === PART_TIME_WORKING_TYPE) {
                    return MAX_PART_TIME_VALUE
                } else {
                    return LEGAL_WORKING_TIME_VALUE
                }
            }
        }
    }))
    Alpine.data("officeComponent", () => ({
        officeRented: YES,
        rentAmount: 0,
        init() {
            this.officeRented = this.$el.dataset.officeRented;
            this.rentAmount = formatNumber(this.$el.dataset.rentAmount);
        },
        officeRentedChange: {
            [":readonly"]() {
                return this.officeRented === NO;
            },
            [":value"]() {
                return this.officeRented === NO ? 0 : formatNumber(this.rentAmount);
            },
            ["@input"](event) {
                this.rentAmount = formatNumber(event.target.value);
            },
            ["@change"](event) {
                this.rentAmount = formatNumber(event.target.value);
            }
        },
    }));

    Alpine.data("switchComponent", (enableValue, disableValue) => ({
        state: enableValue,
        init() {
            this.state = this.$el.dataset.state;
        },
        change: {
            [":disabled"]() {
                return this.state === disableValue;
            },
        },
    }));

    Alpine.data("contractTypeComponent", (enableValue) => ({
        state: enableValue,
        init() {
            this.state = this.$el.dataset.contractType;
        },
        contractTypeChanged: {
            [":disabled"]() {
                return this.state !== enableValue;
            },
        },
    }));

    Alpine.data("openNotificationMessage", () => ({
        async init() {
            const totalHeight = this.getTotalHeight();
            // Position the notification message above the previous ones
            this.$el.style.top = `${totalHeight}px`;
            const notifMessage = new Modal(this.$el, {
                keyboard: false
            });
            notifMessage.show();
            await this.closeModalAuto(notifMessage);
        },
        closeModalAuto(notifMessage) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    notifMessage.hide();
                    resolve();
                }, 4000);
            });
        },
        getTotalHeight() {
            const TOP_ABSOLUTE = 100
            let totalHeight = TOP_ABSOLUTE;
            // Extract the current index from the modalContent ID, formatted as: {idindex}-notif-message
            const idIndex = this.$el.id.split('-')[0];
            const allMessageElements = document.querySelectorAll('[id$="-notif-message"]');
            allMessageElements.forEach((element) => {
                const elementIndex = parseInt(element.id.split('-')[0]);
                // Add the height of previous messages to the total vertical position
                if (elementIndex < idIndex) {
                    totalHeight += element.offsetHeight;
                }
            });
            return totalHeight;

        }

    }));

    Alpine.data("modalDeleteDocument", () => ({
        modalDelete: null,
        init() {
            this.modalDelete = new Modal(this.$el, {
                keyboard: false
            })
        },
        showModal() {
            this.modalDelete.show();
        },
        hideModal() {
            this.modalDelete.hide();
        }
    }));

});

Alpine.start();

Array.from(document.querySelectorAll(".offcanvas")).forEach(
    (offcanvasNode) => new Offcanvas(offcanvasNode),
);

document.addEventListener("htmx:load", () => {
    const popoverTriggerList = document.querySelectorAll(
        '[data-bs-toggle="popover"]',
    );
    const _popoverList = [...popoverTriggerList].map(
        (popoverTriggerEl) => new Popover(popoverTriggerEl),
    );
    const toastElList = document.querySelectorAll(".toast");
    const _toastList = [...toastElList].map((toastEl) => new Toast(toastEl));

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const _tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))


    /* Dropdowns reactivity */

    const dropdownToggleLinks = document.querySelectorAll('a[data-bs-toggle="dropdown"]');

    dropdownToggleLinks.forEach(link => {
        if (!link.hasAttribute('data-listener-added')) {
            link.addEventListener('click', function(event) {
                event.preventDefault();
                
                const dropdownMenu = this.nextElementSibling;
    
                this.classList.toggle('show');
                dropdownMenu.classList.toggle('show');
            });

            link.setAttribute('data-listener-added', 'true')
        }
    });
});

const showToasts = () => {
    const toastElList = document.querySelectorAll(".toast");
    const toastList = [...toastElList].map((toastEl) =>
        Toast.getOrCreateInstance(toastEl),
    );
    toastList.forEach((el) => {
        el.show();
    });
};

document.addEventListener("htmx:afterSettle", showToasts);
document.addEventListener('DOMContentLoaded', showToasts);


